import { Agent, Role } from "../app/StoreTypes";
import { CharacterId } from "../atoms/agents/types";
import { EmptyObject } from "./AccountApiNew";
import api, { APIResponse } from "./APIUtilsNew";

import Integrations from "../config/Integrations";
import { Form, OpenHoursKnowledge } from "./types";
import { ensureHttpsPrefix } from "../helpers/urlHelper";
import { Provider } from "./BusinessApiNew";
import { TeamMemberStatus } from "./TeamApi";

export enum NavSection {
  KNOWLEDGE = "knowledge",
  FLOWS = "flows",
  INTEGRATIONS = "integrations",
}

export enum CancellationAction {
  /** Add means we are ADDING the agent to be scheduled for a deletion */
  ADD = "add",
  /** Remove means we are REMOVING the agent from being scheduled for deletion */
  REMOVE = "remove",
}

export interface GuideItemBase {
  id: number;
  completed: boolean;
}

export interface AgentHint {
  /**
   * Text to be displayed in bold
   */
  boldText?: string;
  /**
   * Text to be displayed normally
   */
  normalText?: string;
  /**
   * A link to a specific section or page
   */
  linkTo?: { dashboardRoute?: string; currentPageRoute?: string; externalUrl?: string };
  /**
   * Button text to be displayed with the hint
   */
  desktopButtonText?: string;
}

export interface GuideItemData {
  data: {
    /**
     * To be used to determine the completion criteria for the guide item
     */
    completionCriteria: {
      /**
       * Regex to match a rendered URL to complete the step
       */
      navigateUrls?: Array<string>;
      /**
       * Element ID to click to complete the step
       */
      clickElementIds?: Array<string>;
      /**
       * Regex to match a rendered URL to exclude from the completion criteria
       */
      excludeUrls?: Array<string>;
    };
    /**
     * The nav section to have flourish
     */
    navFlourish?: NavSection;
    /**
     * Nav hint to be displayed on mobile nav
     */
    mobileNavHint?: AgentHint;
    /**
     * Knowledge page integration hint
     */
    knowledgeIntegrationHint?: {
      /**
       * Hint to be displayed above the integration list
       */
      hint?: AgentHint;
      /**
       * Provider to add button flash - currently only Google Places in knowledge
       */
      providerAddButtonToFlash?: Provider.GooglePlaces;
      /**
       * Integrations to add button flash
       */
      integrationAddButtonToFlash?: Integrations;
    };
    /**
     * Agent hint to be displayed above the deletable skills list
     */
    skillHint?: AgentHint;
    /**
     * Skill highlight to be displayed - true if the first skill should be highlighted, string if a specific skill should be highlighted
     */
    skillHighlight?: true | string;
    /**
     * Skill config to be displayed
     */
    skillConfig?: {
      /**
       * Hint to be displayed above the actions dropdown
       */
      hint?: AgentHint;
      /**
       * Display the action flourish on the dropdown
       */
      actionFlourish?: true;
      /**
       * Display the save button flourish on the save button
       */
      saveButtonFlourish?: true;
    };
    /**
     * Hint to be displayed above the flows list
     */
    flowHint?: AgentHint;
    /**
     * Flow highlight to be displayed - true if the first flow should be highlighted, string if a specific flow should be highlighted
     */
    flowHighlight?: true | string;
    /**
     * Logic highlight to be displayed - true if the first logic should be highlighted, string if a specific logic should be highlighted
     */
    logicHighlight?: true | string;
    /**
     * Flow config to be displayed
     */
    flowConfig?: {
      /**
       *  Hint to be displayed above the flow questions list
       */
      hint?: AgentHint;
      /**
       * Hint to be displayed above the add question button
       */
      questionHint?: AgentHint;
      /**
       * Display the add button flash on the add question button
       */
      addButtonFlash?: true;
      /**
       * Display the save button flash on the save button
       */
      saveButtonFlash?: true;
    };
    /**
     * Hint to be displayed above the integrations list
     */
    integrationHint?: AgentHint;
  };
}

export type PartialGuideItem = GuideItemData;

export type GuideItem = PartialGuideItem & GuideItemBase;

export interface PhoneNumberToReserve {
  phoneNumber: string;
}

export interface AgentCustomer {
  id: string;
  agentId: string;
  callerPhoneNumber: string;
  callerName: string | null;
  dateCreated: Date;
  dateUpdated: Date;
  lastCallDate: Date | null;
  firstCallDate: Date | null;
  totalCalls: number;
  email: string | null;
}

export interface GetAgentCustomersResponse {
  customers: AgentCustomer[];
  meta: {
    count: number;
    pageSize: number;
    currentPage: number;
  };
}

export interface GetAgentCustomersSearchParams {
  currentPage?: number;
  pageSize?: number;
  fromDate?: string;
  searchTerm?: string;
}

type AgentPayload<T> = {
  agentId: string;
  payload: T;
};

type CreateAgentPayload = {
  characterId: CharacterId;
  selectedPhoneNumber?: string;
  businessId: string;
  copyConfigFrom?: string;
  wixInstanceId?: string;
  orderUrl?: string;
  bookingUrl?: string;
  pricingUrl?: string;
  enterpriseId?: string;
};
type ConnectToWixPayload = {
  agentId: string;
  wixInstanceId: string;
};
interface AgentResponse {
  agent: Agent;
}

interface IsBusinessOpenResponse {
  isOpen: boolean;
  currentTime: string;
}

export interface AgentUser {
  id: string;
  email: string;
  roleId: Role;
  teamMemberStatus: TeamMemberStatus;
}

export interface AgentGuideResponse {
  guideItem?: GuideItem;
}

export interface GetAgentUsersResponse {
  users: AgentUser[];
}

export const reservePhoneNumber = async (
  data: AgentPayload<PhoneNumberToReserve>
): Promise<APIResponse<PhoneNumberToReserve>> =>
  api.post<PhoneNumberToReserve, PhoneNumberToReserve>(`/agents/${data.agentId}/phone-number`, data.payload);

export const deleteAgent = async (agentId: string): Promise<APIResponse<EmptyObject>> =>
  api.deleteRequest(`/agents/${agentId}`);

export const isBusinessOpen = async (agentId: string): Promise<APIResponse<IsBusinessOpenResponse>> =>
  api.get(`/agents/${agentId}/is-open`);

export const joinBeta = async (agentId: string, program: string): Promise<APIResponse<{ success: boolean }>> =>
  api.post(`/agents/${agentId}/join-beta`, { program });

export const createAgent = async (data: CreateAgentPayload): Promise<APIResponse<AgentResponse>> =>
  api.post("/agents", data);

export const getAgentUsers = async (agentId: string): Promise<APIResponse<GetAgentUsersResponse>> =>
  api.get(`/agents/${agentId}/users`);

export const removeWixFromAgent = async (agentId: string): Promise<APIResponse<EmptyObject>> =>
  api.deleteRequest(`/agents/${agentId}/connect-to-wix`);

export const connectToWix = async ({
  agentId,
  wixInstanceId,
}: ConnectToWixPayload): Promise<APIResponse<AgentResponse>> =>
  api.post(`/agents/${agentId}/connect-to-wix`, { wixInstanceId });

export const removeIntegration = async (
  agentId: string,
  integrationId: Integrations
): Promise<APIResponse<EmptyObject>> => {
  if (integrationId === Integrations.GoogleSheets) {
    return api.deleteRequest(`/agents/${agentId}/integrations/google/sheets`);
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Unsupported integration: ${integrationId}`);
};

interface FormPayload {
  agentId: string;
  payload: Form;
}

export const getForm = async (agentId: string, formId: string): Promise<APIResponse<{ form: Form }>> =>
  api.get<{ form: Form }>(`/agents/${agentId}/forms/${formId}`);

export const getForms = async (agentId: string): Promise<APIResponse<{ forms: Form[] }>> =>
  api.get<{ forms: Form[] }>(`/agents/${agentId}/forms`);

export const deleteForm = async (agentId: string, formId: string): Promise<APIResponse<EmptyObject>> =>
  api.deleteRequest(`/agents/${agentId}/forms/${formId}`);

export const updateForm = async (form: FormPayload): Promise<APIResponse<{ form: FormPayload["payload"] }>> => {
  const { postFormAction } = form.payload;
  if (postFormAction?.behaviorType === "send-link") {
    postFormAction.link = ensureHttpsPrefix(postFormAction.link);
  }

  return api.put(`/agents/${form.agentId}/forms/${form.payload.id}`, { ...form.payload, postFormAction });
};

export const getCustomers = async (
  agentId: string,
  params: GetAgentCustomersSearchParams
): Promise<APIResponse<GetAgentCustomersResponse>> =>
  api.get<GetAgentCustomersResponse>(`/agents/${agentId}/customers`, { params });

export const getCustomer = async (
  agentId: string,
  customerId: string
): Promise<APIResponse<{ customer: AgentCustomer }>> =>
  api.get<{ customer: AgentCustomer }>(`/agents/${agentId}/customers/${customerId}`);

export const addContactFormQuestion = async (
  agentId: string,
  formQuestions: string[],
  newQuestion: string
): Promise<APIResponse<{ status: "invalid" | "valid" | "warning"; message: string }>> =>
  api.post(`/agents/${agentId}/add-contact-form-question`, { formQuestions, newQuestion });

export const generateNextContactFormQuestion = async (
  agentId: string,
  questions: string[],
  businessName: string,
  businessCategory: string
): Promise<APIResponse<string[]>> =>
  api.post(`/agents/${agentId}/generate-next-contact-form-question`, { businessName, businessCategory, questions });

export const getCurrentAgentGuideStep = async (agentId: string): Promise<APIResponse<AgentGuideResponse>> =>
  api.get<AgentGuideResponse>(`/agents/${agentId}/get-current-guide-step`);

export const markStepAsCompleted = async (agentId: string, stepId: number): Promise<APIResponse<AgentGuideResponse>> =>
  api.post(`/agents/${agentId}/complete-guide-step/${stepId}`, {});

export const scheduleCancellationForAgent = async (
  agentId: string,
  action: CancellationAction = CancellationAction.ADD
): Promise<APIResponse<EmptyObject>> => api.post(`/agents/${agentId}/schedule-deletion/${action}`, {});

export const getAgents = async () => api.get<{ agents: Agent[] }>("/agents");

export const refreshOpenHours = async (agentId: string): Promise<APIResponse<OpenHoursKnowledge>> =>
  api.post<OpenHoursKnowledge, EmptyObject>(`/agents/${agentId}/refresh-open-hours`, {});
