import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import DelayedRender from "./components/DelayedRender";
import NotFound from "./pages/NotFound";
import AddNewLocation from "./componentsTS/addNewLocation";
import CustomBoundary from "./componentsTS/errorBoundaries/CustomBoundary";
import LoggedOutRoute from "./authentication/LoggedOutRoute";
import PageStyles from "./PageStyles";
import Spinner from "./atoms/spinner/Spinner";
import useCheckVersion from "./hooks/useVersionCheck";
import NewVersionBanner from "./componentsTS/newVersionBanner/NewVersionBanner";
import Flow from "./componentsTS/zapier/Flow";
import DashboardRouter from "./authentication/DashboardRouter";

const AddLocationFlow = lazy(() => import("./componentsTS/addNewLocation/AddLocationFlow"));
const EnterpriseFlow = lazy(() => import("./componentsTS/enterprise/EnterpriseFlow"));

const OnboardingFlow = lazy(() => import("./componentsTS/onboarding/OnboardingFlow"));

const AccountDeletedSuccessfully = lazy(() => import("./pages/AccountDeletedSuccessfully"));
const AgentNotFound = lazy(() => import("./pages/AgentNotFound"));

const SecureMyAccount = lazy(() => import("./pages/SecureMyAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Invite = lazy(() => import("./pages/Invite"));
const ViewInvitation = lazy(() => import("./pages/ViewInvitation"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const FinishCreatingAccount = lazy(() => import("./pages/FinishCreatingAccount"));
const ProtectedRoute = lazy(() => import("./authentication/ProtectedRoute"));
const Login = lazy(() => import("./pages/Login"));
const Logout = lazy(() => import("./pages/Logout"));
const WixRoutes = lazy(() => import("./wix/WixRoutes"));
const WixLanding = lazy(() => import("./wix/WixLanding"));
const Impersonate = lazy(() => import("./pages/Impersonate"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

const App = () => {
  const agent = useSelector((state) => state?.agent?.availableAgents)?.[0];
  const { isNewVersionAvailable } = useCheckVersion();
  return (
    <>
      {isNewVersionAvailable && <NewVersionBanner />}
      <PageStyles />
      <Suspense
        fallback={
          <DelayedRender delay={1000}>
            <div className="delayed-render">
              <Spinner />
            </div>
          </DelayedRender>
        }
      >
        <Routes>
          <Route
            path="/enterprise/*"
            element={
              <LoggedOutRoute>
                <EnterpriseFlow />
              </LoggedOutRoute>
            }
          />

          <Route
            path="/dashboard/add-new-location*"
            element={
              <CustomBoundary tag="dashboardError">
                <ProtectedRoute>
                  <AddLocationFlow />
                </ProtectedRoute>
              </CustomBoundary>
            }
          />
          <Route
            path="/dashboard/wix-landing/:wixInstanceId"
            element={
              <CustomBoundary tag="wixLoginError">
                <ProtectedRoute>
                  <WixLanding />
                </ProtectedRoute>
              </CustomBoundary>
            }
          />
          <Route
            path="/dashboard/:agentId/*"
            element={
              <>
                <CustomBoundary tag="dashboardError">
                  <ProtectedRoute>
                    <DashboardRouter />
                  </ProtectedRoute>
                </CustomBoundary>
              </>
            }
          />
          <Route
            path="/zapier/auth*"
            element={
              <ProtectedRoute>
                <Flow />
              </ProtectedRoute>
            }
          />

          <Route path="/dashboard" element={<Navigate to={`/dashboard/${agent?.id}`} replace />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/add-new-location" element={<AddNewLocation />} />
          <Route path="/agent-not-found" element={<AgentNotFound />}></Route>
          <Route path="/impersonate" element={<Impersonate />} />
          <Route
            path="/login*"
            element={
              <CustomBoundary tag="loginError">
                <LoggedOutRoute>
                  <Login />
                </LoggedOutRoute>
              </CustomBoundary>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <LoggedOutRoute>
                <ForgotPassword />
              </LoggedOutRoute>
            }
          ></Route>
          <Route
            path="/reset-password/:token"
            element={
              <LoggedOutRoute>
                <ResetPassword />
              </LoggedOutRoute>
            }
          ></Route>
          <Route path="/account-deleted-successfully" element={<AccountDeletedSuccessfully />}></Route>
          <Route path="/page-not-found" element={<NotFound />} />
          <Route
            path="/get-started*"
            element={
              <CustomBoundary tag="onboardingError">
                <LoggedOutRoute>
                  <OnboardingFlow />
                </LoggedOutRoute>
              </CustomBoundary>
            }
          />
          <Route
            path="*"
            element={
              <>
                <CustomBoundary tag="onboardingError">
                  <Routes>
                    <Route path="/wix*" element={<WixRoutes />} />
                    <Route path="/business-lookup-confirmation/:phone" element={<Navigate to="/get-started" />} />
                    <Route path="/business-lookup" element={<Navigate to="/get-started" />} />
                    <Route path="/business-lookup-failed/:phone" element={<Navigate to="/get-started" />} />
                    <Route path="/secure-my-account" element={<SecureMyAccount />}></Route>
                    <Route path="/invite/:invitationId" element={<Invite />}></Route>
                    <Route path="/view-invitation/:invitationId" element={<ViewInvitation />}></Route>
                    <Route path="/create-account" element={<CreateAccount />}></Route>
                    <Route path="/finish-creating-account" element={<FinishCreatingAccount />}></Route>
                    {/* This route is used to catch anyone loading the app at the index. */}
                    {/* It provides a nice loading screen while we determine whether we */}
                    {/* should redirect them to the dashboard, or to the login page. */}
                    <Route
                      path="/"
                      element={
                        <ProtectedRoute>
                          <Navigate to="/dashboard" />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </CustomBoundary>
              </>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
