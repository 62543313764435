import { agentSlice } from "./slices/agentSlice";
import { businessSlice } from "./slices/businessSlice";
import { userSlice } from "./slices/userSlice";
import { wixUserSlice } from "./slices/wixUserSlice";
import { adminSlice } from "./slices/adminSlice";
import { skillWizardSlice } from "./slices/skillWizardSlice";

import { CharacterId } from "../atoms/agents/types";
import Integrations from "../config/Integrations";
import { Provider } from "../api/BusinessApiNew";

import { PlanName } from "../helpers/newPlanHelper.js";

// It is worth noting here that the plan names returned by the api
// for user and agent, unfortunately, are not consistent. For an agent,
// if they don't have a phone number we infer that they are a demo agent.
// Otherwise, they are a legacy solo agent. For a user, they show as "solo"
// in both cases. To determine if the user is in demo mode, we have to check
// if they own any agents with phone numbers. If they don't, they are in demo mode.
// TODO: Ideally we resolve this inconsistency in the future, but for now, this is how it works.
type UserPlanName = "solo" | PlanName;
type AgentPlanName = "demo" | "solo" | PlanName;

export enum Role {
  ADMIN = "role/admin",
  OWNER = "role/owner",
  EDITOR = "role/editor",
  PERFORMANCE_VIEWER = "role/performance-viewer",
}

export const RoleLabel: Record<Role, string> = {
  [Role.OWNER]: "Owner",
  [Role.ADMIN]: "Admin",
  [Role.EDITOR]: "Editor",
  [Role.PERFORMANCE_VIEWER]: "Performance Viewer",
};
/* Role levels are used to determine the hierarchy of roles. */
export const roleLevels: Record<Role, number> = {
  [Role.OWNER]: 0,
  [Role.ADMIN]: 1,
  [Role.EDITOR]: 2,
  [Role.PERFORMANCE_VIEWER]: 3,
};

export interface UserAgentRole {
  userId: string;
  agentId: string;
  roleId: Role;
}

export interface ActiveUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  intercomUserHash: string;
  trialConsumed: boolean;
  pendingPlanChange?: {
    priceId: string;
    quantity: number;
    startDate: string;
    endDate: string;
  };
  planName: UserPlanName;
  planLocationsUsed: number;
  metadata: {
    enterpriseId?: string;
    voiceId?: string;
  };
  planUsersAllowed: number;
  roles: UserAgentRole[];
  planLocationsAllowed: number;
  subscriptionStatus: string;
  wixLegacyUser: boolean;
  priceId: string;
}

interface FeatureFlags {
  callRatings?: boolean;
  contactForms?: boolean;
  logicFlow?: boolean;
  enableUserConfiguredKnowledge?: boolean;
  nluModel?: string;
  takenMessageToSheets?: boolean;
  useConversationStream?: boolean;
}

interface Integration {
  integrationId: Integrations;
  data: Record<string, unknown>;
}

export interface GoogleSheets extends Integration {
  integrationId: Integrations.GoogleSheets;
  data: {
    spreadsheetId: string;
    spreadsheetName: string;
    spreadsheetUrl: string;
  };
}

interface Zapier extends Integration {
  integrationId: Integrations.Zapier;
}

type IntegrationInfo = GoogleSheets | Zapier;

export interface Agent {
  businessPhoneNumber: string;
  businessCategoryId: number;
  businessName: string;
  businessId: string;
  provider: Provider;
  characterId: CharacterId;
  dateCreated: string;
  featureFlags: FeatureFlags;
  integrations: Array<IntegrationInfo>;
  id: string;
  name: string;
  phoneNumber?: string | false;
  planName: AgentPlanName;
  subscriptionStatus: string;
  wixInstanceId?: string;
  websiteHostname: string;
  linkTrackerEnabled: boolean;
  priceId?: string;
  pendingDelete: boolean;
  pendingPlanChange?: {
    priceId: string;
    quantity: number;
    startDate: string;
    endDate: string;
  };
}

export interface AgentStore {
  availableAgents: Agent[];
  selectedAgent: string;
}

export interface UserStore {
  activeUser: false | ActiveUser;
}

export interface BusinessStore {
  business: {
    id?: string;
  };
}

export type StoreTypes = {
  [agentSlice.name]: ReturnType<typeof agentSlice.reducer>;
  [businessSlice.name]: ReturnType<typeof businessSlice.reducer>;
  [userSlice.name]: ReturnType<typeof userSlice.reducer>;
  [wixUserSlice.name]: ReturnType<typeof wixUserSlice.reducer>;
  [adminSlice.name]: ReturnType<typeof adminSlice.reducer>;
  [skillWizardSlice.name]: ReturnType<typeof skillWizardSlice.reducer>;
};
