import React, { lazy } from "react";
import useUser from "../hooks/useUser";

const Dashboard = lazy(() => import("../pages/Dashboard/index"));
const EnterpriseDashboard = lazy(() => import("../pages/EnterpriseDashboard/index"));

const DashboardRouter = () => {
  const user = useUser();

  const isEnterprise = user?.metadata?.enterpriseId;

  return isEnterprise ? <EnterpriseDashboard /> : <Dashboard />;
};

export default DashboardRouter;
